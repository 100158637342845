import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"
import PostTags from "../components/posttags";
import _ from "lodash";

const Post = styled.div`
  display: grid;
  // background: white;
  padding: 1rem;
  // color: black;
`

const BlurBackground = styled.div`
  left: 10%;
  width: 80%;
  height: 100%;
  position: fixed;
  z-index: -90;
  background: white;
  filter: blur(40px);
  border-radius: 140px;
  `

const Header = styled.div`
  ${'' /* margin: 0 auto; */}
  ${'' /* background: lavenderblush; */}
  margin-top: -1.5rem;
  text-align: right;
  transform: rotate(7deg);
  a {
    font-size: 1.5rem;
    font-family: "Latin Modern Mono";
    font-style: italic;
    color: white;
    -webkit-text-stroke: .6px forestgreen;
    text-decoration: none;
    transition: all 2s;
    :hover {
      -webkit-text-stroke: .5px orchid;
    }
  }
`

const Title = styled.h1`
  font-family: "Latin Modern Roman";
  font-size: 2rem;
  color: #cc7ec9;
  font-style: italic;
  :hover {
    color: #186218;
  }
  transition: all 2s;
  margin-top: 2.5rem;
  margin-bottom: 3rem;
  text-transform: lowercase;
`

const Date = styled.span`
  font-family: Helvetica;
  font-size: .6rem;
  // color: orangered;
  // background: white;
  // border-bottom: 5px solid white;
  letter-spacing: .08rem;
  // padding: .5rem;
  margin-top: 3rem;
`

const Body = styled.div`
  font-size: .8rem;
  font-family: Helvetica;
  margin-right: 3rem;
  // color: black;
  // text-transform: uppercase;
  letter-spacing: .08rem;
`

export default ({ data, pageContext }) => {
  const postNode = data.markdownRemark
  const { slug } = pageContext
  const post = postNode.frontmatter

  return (
    <Layout>
      <SEO title={post.title} />
      <BlurBackground></BlurBackground>
      <Post>
        <Header><Link to="/">index</Link></Header>
        <Date>{post.date}</Date>
        <Title>{post.title}</Title>
        <Body dangerouslySetInnerHTML={{ __html: postNode.html }} />
      </Post>
      {/* <PostTags tags={post.tags} /> */}
      {/* <button>{post.category}</button> */}


      {/* <Link
              key={post.category}
              style={{ textDecoration: "none" }}
              to={`/categories/${_.kebabCase(post.category)}`}
            >
              <button type="button">{category}</button>
      </Link> */}
    </Layout>
  )
}

export const query = graphql`
  query PostPageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        attribution
        date(formatString: "D MMMM YYYY")
        category
        tags
        cover
        accent
      }
    }
  }
`